import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import ScrollToTop from './Navbar/Scrolltop';

import './App.css';
import Footer from './Footer/footer';
import Navbar from './Navbar/Navbar';
import Contact from './pages/Contact';
import Front from './pages/Front';
import Products from './pages/Products';
import BikeDetails from './pages/BikeDetails';
import Services from './pages/Services';
import NewBike from './pages/Admin';
import Login from './pages/Login';
import IBike from './Models/IBike';
import { useEffect } from 'react';
import BikeContext from './Components/BikeContext';
import useToken from './Components/useToken';
import EditBike from './pages/EditBike';
import FilterProvider from './Components/FilterProvider';
import AdminTexts from './pages/AdminTexts'; 
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';



/**
 * App component.
 * 
 * This is the root component of the application. It fetches the bike data from the API, 
 * manages the bike and token state, and renders the different pages of the application.
 * It also initializes the i18next instance for internationalization and localization.
 * 
 * State:
 * - bikes: An array of bike objects. Each object represents a bike and its details.
 * - setBikes: Function to update the bikes state.
 * - token: The user's authentication token.
 * - setToken: Function to update the token state.
 * 
 * Functions:
 * - getData: Fetches the bike data from the API and updates the bikes state.
 * 
 * i18next:
 * - The i18next instance is initialized with the HttpApi and LanguageDetector plugins.
 * - The HttpApi plugin is used to load translations from the '/assets/locales/{{lng}}/translation.json' path.
 * - The LanguageDetector plugin is used to detect the user's language.
 * 
 * @returns The App component.
 */

i18n
  .use(HttpApi) // load translations using http (default public/assets/locales)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    fallbackLng: 'fi', // use fi if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

function App() {
  const [bikes, setBikes] = useState<IBike[]>([]);
  const value = { bikes, setBikes };
  const {token, setToken} = useToken();

  const getData = async () => {
    console.log("getData called");
    const url = process.env.REACT_APP_LOCAL_API_URL || 'https://pyorafunction.azurewebsites.net/api/getbikes';

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'}
        });
        const bikes = await response.json();
        if(response.ok){    
            setBikes(bikes)
        }
    } catch (error) {
        console.log("getData error: " + error);
    }
  }

  library.add(fas)
  useEffect(() => {
    document.title = "Kempeleen pyöräpaja"
    getData();
  }, [])
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
    <BikeContext.Provider value={value}>
      <FilterProvider>
        <Router>
          <ScrollToTop />
          <div className="App">
          <Helmet>
                <meta name="description" content="Kempeleen Pyöräpaja (Business ID: 2500986-1) is a company specializing in bicycle maintenance and repair, as well as the purchase and sale of used bicycles." />
          </Helmet>
          <Navbar />
            <Routes>
              <Route path="/" element={<Front/>} /> 
              <Route path="/products" element={<Products/>} /> 
              <Route path="/services" element={<Services/>} /> 
              <Route path="/contact" element={<Contact/>} /> 
              {
                token ? <Route path="/admin" element={<NewBike/>} /> : <Route path="/admin" element={<Login setToken={setToken}/>} />
              }
              {
                token ? <Route path="/admin/texts" element={<AdminTexts/>} /> : <Route path="/admin/texts" element={<Login setToken={setToken}/>} />
              }
              {
                token ? <Route path="/editbike/:id" element={<EditBike/>} /> : <Route path="/editbike" element={<Login setToken={setToken}/>} />
              }
              <Route path="/bike/:id" element={<BikeDetails/>} />
            </Routes>
            <Footer/>
          </div>
        </Router>
      </FilterProvider>
    </BikeContext.Provider>
    </Suspense>
  );
}

export default App;
