import React, { useEffect, useState, useContext } from 'react';
import BikeDiv from '../Models/BikeDiv';
import BikeContext from '../Components/BikeContext';
import FilterContext from '../Components/FilterContext';
import { useTranslation } from 'react-i18next';
import './Products.css';

/**
 * Products component.
 * 
 * This component displays a list of bikes that can be filtered by price, class, and sorted by a certain order.
 * The user can enter a minimum and maximum price to filter the bikes, select a bike class, and choose a sort order.
 * 
 * The bikes are fetched from the BikeContext.
 * 
 * State:
 * - minPriceFilter: The minimum price entered by the user. Can be a string, a number, or null.
 * - maxPriceFilter: The maximum price entered by the user. Can be a string, a number, or null.
 * - classFilter: The bike class selected by the user. Can be a string or null.
 * - sortOrder: The sort order chosen by the user. Can be a string or null.
 * - areFiltersVisible: A boolean indicating whether the filters are visible or not.
 * 
 * Functions:
 * - handleMinPriceFilterChange: Updates minPriceFilter based on the user's input.
 * - handleMaxPriceFilterChange: Updates maxPriceFilter based on the user's input.
 * 
 * @returns The Products component.
 */
export default function Products() {
    const { t } = useTranslation();
    const { bikes } = useContext(BikeContext);
    const { minPriceFilter, setMinPriceFilter, maxPriceFilter, setMaxPriceFilter, classFilter, setClassFilter, sortOrder, setSortOrder } = useContext(FilterContext);
    const [areFiltersVisible, setAreFiltersVisible] = useState(false);
    const [minTireSizeFilter, setMinTireSizeFilter] = useState<string | null>(null);
    const [maxTireSizeFilter, setMaxTireSizeFilter] = useState<string | null>(null);

    const handleMinPriceFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinPriceFilter(Number(event.target.value));
    };
    
    const handleMaxPriceFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxPriceFilter(Number(event.target.value));
    };
    
    const handleClassFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setClassFilter(event.target.value);
    };
    
    const handleSortOrderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortOrder(event.target.value);
    };

    const handleMinTireSizeFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinTireSizeFilter(event.target.value);
    };

    const handleMaxTireSizeFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxTireSizeFilter(event.target.value);
    };

    const toggleFilters = () => {
        setAreFiltersVisible(!areFiltersVisible);
    };

    const resetFilters = () => {
        setMinPriceFilter(null);
        setMaxPriceFilter(null);
        setClassFilter(null);
        setSortOrder('');
        setMinTireSizeFilter(null);
        setMaxTireSizeFilter(null);
    };

    let filteredBikes = bikes.filter(bike => {
        return (minPriceFilter ? bike.price >= Number(minPriceFilter) : true) &&
            (maxPriceFilter ? bike.price <= Number(maxPriceFilter) : true) &&
            (classFilter ? bike.class === classFilter : true) &&
            (minTireSizeFilter ? bike.tireSize >= minTireSizeFilter : true) &&
            (maxTireSizeFilter ? bike.tireSize <= maxTireSizeFilter : true);
    });

    if (sortOrder === 'asc') {
        filteredBikes.sort((a, b) => a.price - b.price);
    } else if (sortOrder === 'desc') {
        filteredBikes.sort((a, b) => b.price - a.price);
    }

    return (
        <div className="Productspage">
            <h1>{t('products.title')}</h1>
            <div className='Products-content'>
                <div className="Controls">
                    <button className="toggleFiltersButton" onClick={toggleFilters}>{areFiltersVisible ? t('products.hideFilters') : t('products.showFilters')}</button>
                    <select value={sortOrder} onChange={handleSortOrderChange}>
                         <option value="">{t('products.sortByPrice')}</option>
                        <option value="asc">{t('products.cheapestFirst')}</option>
                        <option value="desc">{t('products.mostExpensiveFirst')}</option>
                    </select>
                </div>
                {areFiltersVisible && (
                        <div className="Filters">
                            <p>{t('products.price')}:</p>
                            <input type="number" value={minPriceFilter || ''} onChange={handleMinPriceFilterChange} placeholder="Min" />
                            <input type="number" value={maxPriceFilter || ''} onChange={handleMaxPriceFilterChange} placeholder="Max" />
                            <p>{t('products.gender')}:</p>
                            <select value={classFilter || ''} onChange={handleClassFilterChange}>
                                <option value="">{t('products.all')}</option>
                                <option value="M">{t('products.mensBikes')}</option>
                                <option value="N">{t('products.womensBikes')}</option>
                                <option value="L">{t('products.kidsBikes')}</option>
                            </select>
                            <p>{t('products.tireSize')}:</p>
                            <input type="text" value={minTireSizeFilter || ''} onChange={handleMinTireSizeFilterChange} placeholder="Min" />
                            <input type="text" value={maxTireSizeFilter || ''} onChange={handleMaxTireSizeFilterChange} placeholder="Max" />
                            <div className="ResetFilters">
                                <button onClick={resetFilters}>{t('products.clearSelections')}</button>
                            </div>
                        </div>
                    )}
                <div className='BikesContainer'>
                    {filteredBikes.map(Bike => (
                        <BikeDiv key={Bike.id} {...Bike} />
                    ))}
                </div>
            </div>
        </div>
    )
}